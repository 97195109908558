.navbar {
    position: fixed;
    top: 0;
    width: calc(100vw - 17px);
    height: 70px;
    background-color: var(--primary-bg-color);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    border-bottom: 3px solid white;
    z-index: 999;
}
.navbar img {
    height: 60px;
    cursor: pointer;
}
.navbar a {
    text-decoration: none;
    font-size: 26px;
    margin: 10px;
    color: var(--primary-item-font-color);
}
.navbar a:hover{
    color: rgb(247, 203, 177);
}
@media screen and (max-width: 800px) {
    .active {
        display: flex;
        right: 0;
    }
    .desactive {
        display: none;
        right: -50vw;
    }
    .navbar a {
        font-size: 24px;
    }
    .navbar .links {
        position: fixed;
        top: 70px;
        width: 50vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        background-color: var(--primary-bg-color);
    }
    .navbar {
        width: 100vw;
        justify-content: space-between;
    }
    .hamburguer {
        width: 40px;
        height: 40px;
        border: 2px solid white;
        border-radius: 7px;
        margin-right: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .hamburguer .line {
        width: 30px;
        height: 0px;
        border: 2px solid white;
        background-color: white;
        margin: 4px;
        border-radius: 7px;
    }
}