.home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    width: 100%;
    scroll-behavior: smooth;
}
.home h1 {
    font-size: 46px;
    filter: drop-shadow(3px 3px 3px #000000d3);
    text-align: center;
    -webkit-text-stroke: 0.5px rgba(0, 0, 0, 0.445);
}
.home .banner {
    width: 100%;
    height: 200px;
}
#banner1 {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0px;
}
#banner2 {
    width: calc(100vw - 20px);
    height: 100vh;
    position: absolute;
    top: 100vh;
    opacity: 0.4;
    filter: blur(2px) grayscale(40%);
}
.headerPage {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
}
.headerPage h1 {
    text-shadow: 3px 3px 15px black;
}
#latoxilogo{
    margin-top: -150px;
    margin-bottom: 140px;
}
.boxes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    height: 100vh;
}
.second {
    flex-direction: column;
    justify-content: center;
}
.second h2 {
    color: #030303;
    filter: drop-shadow(2px 2px 2px #ffffff80);
}
.second strong {
    color: var(--primary-bg-color);
}
.boxes .text1 {
    margin-top: 10px;
    text-align: left;
}
.boxes .text1 h1 {
    color: var(--primary-bg-color);
}
.box {
    width: 26vw;
    height: 26vw;
    background-color: var(--primary-item-bg-color);
    margin: 15px;
    border-radius: 15px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    box-shadow: 3px 3px 25px rgb(48, 48, 48);
    text-align: center;
    overflow: hidden;
}
.box .boxHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.box .boxBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.box .boxFooter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.boxBody img{
    width: 100px;
}
.boxes .box2 {
    width: 43vw;
    height: 26vw;
    background-color: var(--primary-item-bg-color);
    margin: 100px 15px 0 15px;
    border-radius: 15px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    box-shadow: 3px 3px 25px rgb(48, 48, 48);
}
.boxes .text2 {
    width: 43vw;
    height: 26vw;
    margin: 100px 15px 0 15px;
    border-radius: 15px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.boxes .text2 {
    color: var(--primary-bg-color);
    font-size: 24px;
}
.box2 iframe {
    width: 37vw;
    height: 22vw;
    position: relative;
    top: -10px;
    border-radius: 15px;
    border: none;
}
.box2 a {
    text-decoration: none;
    color: var(--primary-item-font-color);
}
.box h2 {
    font-size: 35px;
}
.box .valor {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 10px;
    margin: 10px;
    border-radius: 15px;
    color: #16ec16;
    width: 50%;
}
.box .valor h1 {
    text-shadow: 5px 5px 10px rgb(209, 209, 209);
}
.box .valor .cifra span{
    color: #15d115;
}
.box .valor .cifra h1{
    position: relative;
    padding-left: 20px;
    padding-right: 40px;
}
.box .valor .cifra h1 .cent {
    position: absolute;
    top: 10px;
}
.box .valor span {
    color: black;
    text-align: center;
    font-size: 14px;
}
.buttonS {
    margin-top: 20px;
    width: 200px;
    height: 50px;
    background-color: var(--primary-item-bg-color);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}
.buttonS:hover{
    background-color: #f1690e;
}
.buttonS a {
    text-decoration: none;
    color: white;
    font-size: 24px;
    margin-left: 7px;
}
.buttonS img {
    width: 30px;
}
.payment .paymentMethods {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: white;
}
.payment .paymentMethods li {
    margin: 5px;
}
.payment .paymentMethods li img {
    width: 30px;
}
.box .list h2{
    font-size: 26px;
}
@media screen and (max-width: 800px) {
    .headerPage {
        height: 60vh;
        text-align: center;
    }
    #latoxilogo {
        display: none;
    }
    #banner1 {
        height: 60vh;
    }
    #banner2 {
        display: none;
        /* top: 60vh;
        width: 100vw;
        height: 65vh; */
    }
    .home h1 {
        margin-top: -10px;
        font-size: 26px;
    }
    .second h1 {
        filter: none;
        text-align: center;
        -webkit-text-stroke: 0;
        color: var(--primary-bg-color);
    }
    .second h2 {
        font-size: 14px;
    }
    .valor h1{
        font-size: 48px;
    }
    .boxes {
        flex-direction: column;
        justify-content: flex-start;
        height: 60vh;
    }
    .box {
        width: 73vw;
        height: 80vw;
    }
    .boxes .box2 {
        margin-top: 40px;
        width: 78vw;
        height: 80vw;
    }
    .box h2 {
        font-size: 30px;
    }
    .box ul {
        margin-left: 10px;
    }
    .payment .paymentMethods li img {
        width: 25px;
    }
    .box .buttonS a {
        font-size: 22px;
        margin-left: 7px;
    }
    .box2 iframe {
        width: 80vw;
        height: 70vw;
        position: relative;
        top: -10px;
        border-radius: 15px;
        border: none;
    }
    .boxes .text2 {
        display: none;
    }
}