*, html{
    padding: 0;
    margin: 0;
    outline: 0;
    transition: 0.5s;
}
:root {
    --primary-bg-color: #ff8500;
    --secundary-bg-color: #ffffff;
    --primary-item-font-color: #ffffff;
    --primary-item-bg-color: #ff8400;
}
a {
    cursor: pointer;
}