.footer {
    position: relative;
    top: 20px;
    width: calc(100vw - 17px);
    min-height: 70px;
    background-color: var(--primary-bg-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    border-bottom: 3px solid white;
    margin: 0;
}
.footer img {
    margin-top: 20px;
    height: 60px;
    cursor: pointer;
}
.footer .items {
    width: calc(100% - 60px);
    padding: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.footer .items ul {
    list-style-type: none;
    font-size: 18px;
    font-weight: 500;
}
.footer .items a {
    text-decoration: none;
    color: var(--primary-item-font-color);
}
@media screen and (max-width: 800px) {
    .footer {
        width: 100vw;
    }
}