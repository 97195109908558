.main {
    position: relative;
    top: 70px;
    background-color: var(--secundary-bg-color);
    width: calc(100vw - 17px);
    min-height: calc(100vh - 70px);
    color: var(--primary-item-font-color);
}
.main .page {
    padding: 20px;
    min-height: calc(100vh - 110px);
}

@media screen and (max-width: 800px) {
    .main {
        min-width: none;
        width: 100vw;
    }
    .main .page {
        height: auto;
    }
}